import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className='py-footer'>

                <div className='top-footer'>
                    <Container>
                        <Row>
                            <Col>
                                <p>Sponsored by Rabbi Roberto and Margie Szerer In loving memory of Victor Chayim Ben
                                    Margot Z''L and Gladys Szerer Sarah Bat Leah Z'''L"</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className='bottom-footer'>
                    <Container>
                        <Row>
                            <Col>
                                <Image src="/images/footer-logo.png" className="footer-logo" width={46} height={39} alt='footer-logo' importance="high"/>
                                <ul>
                                    <li>
                                        <Link legacyBehavior href="/about"><a href={`/about`} style={{color: 'whitesmoke'}}>About </a></Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <p>&copy; TorahTable {new Date().getFullYear()}</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </footer>
        </>
    )
}

export default Footer
