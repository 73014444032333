import Link from 'next/link';
import Image from 'next/image';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {getInitials} from '@/utils';
import {signIn, signOut, useSession} from 'next-auth/react';
import {toast} from 'react-toastify';
import React, {useContext, useEffect, useRef, useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {useRouter} from 'next/router';
import {AppContext} from '@/context/AppContext';

const Header = () => {
    const { data: session } = useSession();

    const {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        handlePrint
    } = useContext(AppContext);

    const router = useRouter();

    const containerRef = useRef();

    const [IsMenuOpened, setIsMenuOpened] = useState(false);

    useEffect(() => {

        function handleClickOutside(event) {

            document.querySelector("body").classList.remove('menuOpen');

            if (containerRef.current && !containerRef.current.contains(event.target)) {
                document.querySelector("#navbar_main").classList.remove("show");
                setIsMenuOpened(false);
            }

            if (document.querySelector(".selectOpen")) {
                if (!document.querySelector(".selectOpen").contains(event.target)) {
                    document.querySelector(".selectOpen").classList.remove('selectOpen')
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [containerRef]);

    const handleCloseNavbar = (e) => {
        //setIsMenuOpened(wasOpened => !wasOpened);

        e.preventDefault();
        let element = document.querySelector("#navbar_main");
        element.classList.remove("show");
        setIsMenuOpened(false);

    };

    const handleNavbar = (e) => {
        //setIsMenuOpened(wasOpened => !wasOpened);
        e.preventDefault();
        let element = document.querySelector("#navbar_main");
        element.classList.add("show");

        if (IsMenuOpened) {
            setIsMenuOpened(false);
            document.querySelector("body").classList.remove('menuOpen');

        }
        else {
            setIsMenuOpened(true);
            document.querySelector("body").classList.add('menuOpen');
            //
        }
        // element.classList.toggle("show");
    };

    const renderAuthButton = () => {

        if (session) {
            return (
                <Navbar.Collapse className="" id="basic-navbar-nav">

                    <Nav className="me-auto d-flex justify-content-between w-100">
                        <div className="nav-bar-items">
                            <Nav
                                className={`${router.pathname === "/followings" ? "nav-item active" : "nav-item"}`}
                            >
                                <Link legacyBehavior href="/followings"><a className="nav-link">Following </a></Link>

                            </Nav>
                            <Nav
                                className={`${router.pathname === "/bookmarks" ? "nav-item active" : "nav-item"}`}
                            >
                                <Link legacyBehavior href="/bookmarks"><a className="nav-link">Bookmarks </a></Link>

                            </Nav>
                        </div>
                        <NavDropdown title={`${firstName?.charAt(0)} ${lastName?.charAt(0)}`} id="navbarScrollingDropdown">
                            <NavDropdown.Item disabled className="text-primary">
                                <strong>{firstName} {lastName}</strong> <br />
                                {email}
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                                <Nav>
                                    <Link legacyBehavior href="/myaccount">Profile</Link>
                                </Nav>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Nav>
                                    <Link legacyBehavior href="/upload">Upload</Link>
                                </Nav>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                                <Nav>
                                    <Link legacyBehavior href="/myuploads">My Uploads</Link>
                                </Nav>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/api/auth/signout" onClick={(e) => {
                                e.preventDefault();
                                signOut({
                                    callbackUrl: `${window.location.origin}`
                                }).then(() => {
                                    toast.success('Logging you out ...', { position: "top-right" })
                                });

                            }}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>)
        } else {
            return (
                <>
                    <ul className="navbar-nav">
                        <li
                            className={`${router.pathname === "/" ? "nav-item active mr-4" : "nav-item mr-4"}`}
                        >
                            <Link legacyBehavior href="/"><a className="nav-link">Browse </a></Link>
                            {/*Browse*/}
                            {/*<span className="nav-link" >Browse </span>*/}
                        </li>
                        <li
                            className={`${router.pathname === "/about" ? "nav-item active mr-4" : "nav-item mr-4"}`}
                        >
                            <Link legacyBehavior href="/about"><a className="nav-link">About </a></Link>
                            {/*About*/}
                            {/*<span className="nav-link">About </span>*/}
                        </li>
                        <li
                            className={`${router.pathname === "/login" ? "nav-item active mr-4" : "nav-item mr-4"}`}
//                            onClick={() => signIn("google", {callbackUrl: process.env.NEXTAUTH_URL})}
                        >
                            <Link legacyBehavior href="/login"><a className="nav-link">Login </a></Link>
                        </li>
                    </ul>
                </>

            )
        }
    };

    return (
        <>
            <header className="py-header" ref={containerRef}>
                <nav className="navbar d-lg-none navbar-expand-lg navbar-light">
                    <div className="container">
                        {IsMenuOpened ?
                            <button className="navbar-toggler" aria-label="Close Navbar" type="button" onClick={handleCloseNavbar}><span></span>
                            </button>
                            :
                            <button className="navbar-toggler" type="button" aria-label="Handle Navbar" onClick={handleNavbar}><span className="navbar-toggler-icon"></span></button>
                        }
                        <Link href="/" className="navbar-brand">
                            <Image src="/images/torahtable_logo.png" alt="Torah Table" width={206} height={39}/>
                        </Link>

                        {session ?
                            <NavDropdown title={`${firstName?.charAt(0)} ${lastName?.charAt(0)}`} id="navbarScrollingDropdown">
                                <NavDropdown.Item href="#">
                                    <strong>{firstName} {lastName}</strong> <br/>
                                    {email}
                                </NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item href="/myaccount">Profile</NavDropdown.Item>
                                <NavDropdown.Item href="/upload">Upload</NavDropdown.Item>
                                <NavDropdown.Item href="/myuploads">My Uploads</NavDropdown.Item>
                                <NavDropdown.Item href="/api/auth/signout" onClick={async (e) => {
                                    e.preventDefault();
                                    await signOut({
                                        callbackUrl: `${window.location.origin}`
                                    });
                                    toast.success('You’ve been successfully logged out', {position: "top-right"})
                                }}>Logout</NavDropdown.Item>
                            </NavDropdown>
                            : ''}

                    </div>
                </nav>
                <nav id="navbar_main" className="mobile-offcanvas navbar navbar-expand-lg navbar-light">
                    <div className="container nav-cont">
                        <Link href="/" className="navbar-brand d-none d-lg-block">
                            <Image src="/images/torahtable_logo.png" alt="Torah Table" width={206} height={39}/>
                        </Link>

                        {renderAuthButton()}
                    </div>
                </nav>
            </header>


        </>
    )
}

export default Header;
